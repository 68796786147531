import { render, staticRenderFns } from "./loading-transaction-card.vue?vue&type=template&id=6eb2d1b6&scoped=true&"
import script from "./loading-transaction-card.vue?vue&type=script&lang=js&"
export * from "./loading-transaction-card.vue?vue&type=script&lang=js&"
import style0 from "./loading-transaction-card.vue?vue&type=style&index=0&id=6eb2d1b6&lang=scss&scoped=true&"
import style1 from "./loading-transaction-card.vue?vue&type=style&index=1&id=6eb2d1b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb2d1b6",
  null
  
)

export default component.exports
import {QCard,QCardSection,QSkeleton} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QSkeleton})
