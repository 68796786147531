//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { ReviewsPermissions } from '@/modules/transactions/transactions-permissions';
import { i18n } from '@/vueI18n';
import moment from 'moment';

// import { ReviewsModel } from '@/modules/transactions/transactions-model';
// import ReviewsViewModal from '@/modules/transactions/components/transactions-view-modal.vue';

// import { FormSchema } from '@/shared/form/form-schema';
// const { fields } = ReviewsModel;

export default {
  name: 'app-transaction-card',

  props: {
    userId: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    } 
  },

  data() {
    return {
      dialogVisible: false,
      modalVisible: false,
      model: null,
    }
  },
  computed: {
    ...mapGetters({
      // record: 'transactions/view/record',
      // loading: 'transactions/view/loading',
      // destroyLoading: 'transactions/destroy/loading',
      // changeStatusLoading: 'transactions/form/changeStatusLoading',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    ...mapActions({
      // doFind: 'transactions/view/doFind',
      // doDestroy: 'transactions/destroy/doDestroy',
      // doDisable: 'transactions/form/doDisable',
      // doEnable: 'transactions/form/doEnable',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenterDate(val) {
      return moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    // onOpenModal() {
    //   this.modalVisible = true;
    //   this.dialogVisible = true;
    // },
    // onModalClose() {
    //   this.dialogVisible = false;
    //   setTimeout(() => {
    //     this.modalVisible = false;
    //   }, 200);
    // },

  },
};
