//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TransactionCard from '@/modules/transaction/components/partial/transaction-card.vue';
import LoadingTransactionCard from '@/modules/transaction/components/partial/loading-transaction-card.vue';

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
// import { Screen } from 'quasar'

export default {
  name: 'app-transaction-list-card',
  props: {
    userId: {
      type: String,
      required: false,
    },
    amount: {
      type: Number,
      required: false,
    },
    // transactions: {
    //   type: Array,
    //   required: true,
    // },
    // loading: {
    //   type: Boolean,
    //   required: false,
    // }
  },

  components: {
    [TransactionCard.name]: TransactionCard,
    [LoadingTransactionCard.name]: LoadingTransactionCard,
  },

  mounted() {
    this.doFilter();
    // this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      tab: 'income',
      rowsToBeDisplayed: [],
      transactions: [],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'transaction/list/rows',
      loading: 'transaction/list/loading',
      moreRows: 'transaction/list/moreRows',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    loadingNum() {
      if (this.is_screen_sm || this.is_screen_xs) return 1; 
      if (this.is_screen_md) return 2; 
      return 3
    },
  },
  methods: {
    ...mapActions({
      resetCursor: 'transaction/list/resetCursor',
      setLoadMoreLast: 'transaction/list/setLoadMoreLast',
      doFetchUserIncomeTransaction: 'transaction/list/doFetchUserIncomeTransaction',
      doFetchUserExpenseTransaction: 'transaction/list/doFetchUserExpenseTransaction',
      doFetchMoreIncomeTransaction: 'transaction/list/doFetchMoreIncomeTransaction',
      doFetchMoreExpenseTransaction: 'transaction/list/doFetchMoreExpenseTransaction',
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
    async doFilter() {
      await this.resetCursor()
      switch (this.tab) {
        case 'income':
          await this.doFetchUserIncomeTransaction({ id: this.userId, limit: 10 })
          break;
        case 'expense':
          await this.doFetchUserExpenseTransaction({ id: this.userId, limit: 10 })
          break;
        default:
          break;
      }
      // this.rowsToBeDisplayed = this.rows
      // this.transactions = this.rows
    },
    doFetchMore() {
      switch (this.tab) {
        case 'income':
          this.doFetchMoreIncomeTransaction({ id: this.userId, limit: 10 })
          break;
        case 'expense':
          this.doFetchMoreExpenseTransaction({ id: this.userId, limit: 10 })
          break;
        default:
          break;
      }
    },
    onLoad(index, done) {
      console.log('index = ', index);
      // if (index > 1) {
        setTimeout(() => {
          if (this.moreRows) {
            this.doFetchMore()
            done()
          }
        }, 2000)      
      // } else {
      //   setTimeout(() => {
      //     if (this.moreRows) {
      //       done()
      //     }
      //   }, 200)      
      // }
    },
    onLoadTransaction (index, done) {
      console.log('index = ', index);
      setTimeout(() => {
        for (let index = 0; index < this.loadingNum; index++) {
          this.transactions.push({
            id: `150${index}`,
            description: {
              en: 'Credit Added',
              ar: 'تمت إضافة رصيد',
            },
            payMethod: '',
            date: '2021-02-08T20:59:08.103Z',
            amount: 250,
            currency: 'SR',
            createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
            updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
            createdAt: '2021-02-08T20:59:08.103Z',
            updatedAt: '2021-02-08T20:59:08.103Z',
          })
        }
        done()
      }, 2000)
    },
    
  },
};
