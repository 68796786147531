import { render, staticRenderFns } from "./transaction-card.vue?vue&type=template&id=0ab94636&scoped=true&"
import script from "./transaction-card.vue?vue&type=script&lang=js&"
export * from "./transaction-card.vue?vue&type=script&lang=js&"
import style0 from "./transaction-card.vue?vue&type=style&index=0&id=0ab94636&lang=scss&scoped=true&"
import style1 from "./transaction-card.vue?vue&type=style&index=1&id=0ab94636&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ab94636",
  null
  
)

export default component.exports
import {QCard,QCardSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection})
